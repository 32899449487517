export function DashboardIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="chart-pie">
                <path
                    id="Vector"
                    d="M2.39999 12.0004C2.39999 9.45431 3.41142 7.01252 5.21177 5.21217C7.01212 3.41182 9.45392 2.40039 12 2.40039V12.0004H21.6C21.6 14.5465 20.5886 16.9883 18.7882 18.7886C16.9879 20.589 14.5461 21.6004 12 21.6004C9.45392 21.6004 7.01212 20.589 5.21177 18.7886C3.41142 16.9883 2.39999 14.5465 2.39999 12.0004Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_2"
                    d="M14.4 2.70215C16.0603 3.1323 17.5754 3.99869 18.7883 5.21149C20.0011 6.4243 20.8674 7.9394 21.2976 9.59975H14.4V2.70215Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
