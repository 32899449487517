import '@/styles/globals.css';
import '@clidrive/ui/styles.css';
import type { AppProps } from 'next/app';
import { UserProvider, useUser } from '@auth0/nextjs-auth0/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DialogHandler } from '@/components/Dialog/DialogHandler';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { es } from 'date-fns/locale';
import { PropsWithChildren, useEffect, useState } from 'react';
import Login from './login';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/browser';
import { Roles, useProfileStore } from '@/stores/useProfileStore';
import { ApiClientError } from '@/lib/client/ApiClientError';
import { useRouter } from 'next/router';
export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
    const [queryClient] = useState(() => new QueryClient());
    const router = useRouter();

    if (typeof window !== 'undefined') {
        if (!process.env.NEXT_PUBLIC_POSTHOG_KEY || !process.env.NEXT_PUBLIC_POSTHOG_HOST) {
            throw new Error('POSTHOG env vars are not set');
        }

        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
            api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
        });
    }

    useEffect(() => {
        const loginRedirect = window.localStorage.getItem('loginRedirect');
        if (loginRedirect) {
            window.localStorage.removeItem('loginRedirect');
            router.push(loginRedirect);
        }

        const unsubscribe = queryClient.getQueryCache().subscribe(() => {
            queryClient
                .getQueryCache()
                .findAll()
                .forEach(async (query) => {
                    if (query.state.error) {
                        const error = query.state.error as ApiClientError;
                        if (error.statusCode === 401 && typeof window !== 'undefined') {
                            if (window.location.pathname !== '/login')
                                window.localStorage.setItem('loginRedirect', window.location.pathname);

                            await router.push('/login');
                        }
                    }
                });
        });

        return () => unsubscribe();
    }, []);

    return (
        <PostHogProvider>
            <UserProvider>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                        <DialogHandler>
                            <UserSessionProvider>
                                <Component {...pageProps} />
                            </UserSessionProvider>
                        </DialogHandler>
                    </LocalizationProvider>
                </QueryClientProvider>
            </UserProvider>
        </PostHogProvider>
    );
}

function UserSessionProvider({ children }: PropsWithChildren) {
    const { isLoading, user } = useUser();
    const { setProfileRole } = useProfileStore();

    const getProfile = async () => {
        try {
            const response = await fetch(`/api/users/me`, {
                method: 'GET',
            });

            const responseData = await response.json();

            return responseData;
        } catch (e) {
            return [];
        }
    };

    useEffect(() => {
        if (!isLoading && user) {
            const getProfileData = async () => {
                const profile = await getProfile();
                setProfileRole(profile.roles as Roles[]);
            };

            getProfileData();

            posthog.identify(user.sub ?? '', user);
            Sentry.setUser({
                email: user.email ?? '',
            });
        }
    }, [isLoading, user]);

    if (isLoading) {
        // TODO: Add connect loading screen
        return <div></div>;
    }

    if (!user) {
        return <Login />;
    }

    return <>{children}</>;
}
