export function DealsIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="phone-outgoing">
                <path
                    id="Vector"
                    d="M21.5088 3.14079C21.4489 2.99637 21.3612 2.86509 21.2508 2.75439L21.246 2.74959C21.0214 2.52566 20.7171 2.40006 20.4 2.40039H15.6C15.2817 2.40039 14.9765 2.52682 14.7515 2.75186C14.5264 2.97691 14.4 3.28213 14.4 3.60039C14.4 3.91865 14.5264 4.22388 14.7515 4.44892C14.9765 4.67396 15.2817 4.80039 15.6 4.80039H17.5032L13.5516 8.75199C13.333 8.97831 13.2121 9.28144 13.2148 9.59607C13.2175 9.91071 13.3437 10.2117 13.5662 10.4342C13.7887 10.6567 14.0897 10.7829 14.4043 10.7856C14.719 10.7883 15.0221 10.6674 15.2484 10.4488L19.2 6.49719V8.40039C19.2 8.71865 19.3264 9.02388 19.5515 9.24892C19.7765 9.47396 20.0817 9.60039 20.4 9.60039C20.7183 9.60039 21.0235 9.47396 21.2485 9.24892C21.4736 9.02388 21.6 8.71865 21.6 8.40039V3.60039C21.6002 3.44268 21.5692 3.28648 21.5088 3.14079Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_2"
                    d="M2.39999 3.60039C2.39999 3.28213 2.52642 2.97691 2.75147 2.75186C2.97651 2.52682 3.28173 2.40039 3.59999 2.40039H6.18359C6.46764 2.40052 6.74243 2.50141 6.95909 2.6851C7.17574 2.8688 7.32021 3.12339 7.36679 3.40359L8.25479 8.72559C8.29722 8.97905 8.25716 9.23944 8.14051 9.46843C8.02386 9.69741 7.83677 9.8829 7.60679 9.99759L5.74919 10.9252C6.41532 12.576 7.40739 14.0755 8.66612 15.3343C9.92486 16.593 11.4244 17.5851 13.0752 18.2512L14.004 16.3936C14.1186 16.1638 14.3039 15.9769 14.5327 15.8603C14.7614 15.7436 15.0215 15.7034 15.2748 15.7456L20.5968 16.6336C20.877 16.6802 21.1316 16.8246 21.3153 17.0413C21.499 17.258 21.5999 17.5327 21.6 17.8168V20.4004C21.6 20.7187 21.4736 21.0239 21.2485 21.2489C21.0235 21.474 20.7183 21.6004 20.4 21.6004H18C9.38399 21.6004 2.39999 14.6164 2.39999 6.00039V3.60039Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
