import { Toast } from 'flowbite-react';
import {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { HiCheck, HiOutlineExclamation, HiX } from 'react-icons/hi';

type DialogType = 'modal' | 'toast';

export type Dialog = {
    visible?: boolean;
    content: React.ReactNode;
    type: DialogType;
};

type ModalProps = {
    type: 'modal';
};

type ToastProps = {
    type: 'toast';
    variant: 'error' | 'success' | 'warning';
};

export type DialogProps = Dialog & (ModalProps | ToastProps);

const dialogDefaultState: DialogProps = {
    visible: false,
    content: <></>,
    type: 'modal',
};

const DialogContextHandler = createContext<DialogHandlerContextValue>({
    state: dialogDefaultState,
    setState: () => {},
});

interface DialogHandlerContextValue {
    state: DialogProps;
    setState: Dispatch<SetStateAction<DialogProps>>;
}

export function DialogHandler({ children }: PropsWithChildren) {
    const [state, setState] = useState(dialogDefaultState);
    const value = useMemo(() => ({ state, setState }), []);
    return (
        <DialogContextHandler.Provider value={value}>
            <div
                className={`fixed h-full w-full ${
                    state.visible && state.type === 'modal' ? 'overflow-auto' : 'overflow-hidden'
                }`}
            >
                {children}
            </div>
            {state.visible && <DialogContent props={state}>{state.content}</DialogContent>}
        </DialogContextHandler.Provider>
    );
}

export function DialogContent({ children, props }: PropsWithChildren<{ props: DialogProps }>) {
    if (props.type === 'modal') {
        return <ModalContent>{children}</ModalContent>;
    }

    return (
        <ToastContent props={props}>
            <p className="whitespace-pre-line">{children}</p>
        </ToastContent>
    );
}

export function ModalContent({ children }: PropsWithChildren) {
    return <>{children}</>;
}

export function ToastContent({ children, props }: PropsWithChildren<{ props: ToastProps }>) {
    useDismissOnTimeout(5000);
    return (
        <Toast className="fixed bottom-4 left-1/2 z-[99999] flex min-w-fit -translate-x-1/2 items-center justify-center text-black">
            <div
                className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg
                    ${props.variant === 'success' ? 'bg-green-100 text-green-500' : ''}
                    ${props.variant === 'error' ? 'bg-red-100 text-red-500' : ''}
                    ${props.variant === 'warning' ? 'bg-orange-100 text-orange-500' : ''}`}
            >
                {props.variant === 'success' ? <HiCheck className="h-5 w-5" /> : ''}
                {props.variant === 'error' ? <HiX className="h-5 w-5" /> : ''}
                {props.variant === 'warning' ? <HiOutlineExclamation className="h-5 w-5" /> : ''}
            </div>
            <div className="ml-3 flex-1  pr-2 text-sm font-normal">{children}</div>
            <div className="text-gray-600">
                <DialogDismissButton />
            </div>
        </Toast>
    );
}

export const handleDismissModal = (state: DialogProps, setState: (state: DialogProps) => void) => {
    setState({ ...state, visible: false });
};
export const useDialogHandler = () => useContext(DialogContextHandler);

export function DialogDismissButton() {
    const { state, setState } = useDialogHandler();
    return (
        <div className="mx-auto rounded-lg p-1 hover:bg-gray-100">
            <HiX className="h-5 w-5 cursor-pointer" onClick={() => handleDismissModal(state, setState)} />
        </div>
    );
}

export const useDialog = () => {
    const { setState } = useDialogHandler();
    return useCallback(
        (dialog: DialogProps) => {
            setState({ ...dialog, visible: true });
        },
        [setState],
    );
};

function useDismissOnTimeout(timeout: number) {
    const { state, setState } = useDialogHandler();
    return useEffect(() => {
        const timer = setTimeout(() => {
            handleDismissModal(state, setState);
        }, timeout);
        return () => clearTimeout(timer);
    }, []);
}
