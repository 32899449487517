import { cva } from 'class-variance-authority';
import { HTMLAttributes } from 'react';

type ButtonColors =
    | 'light-blue'
    | 'blue'
    | 'white'
    | 'gray'
    | 'light-gray'
    | 'red'
    | 'green'
    | 'purple'
    | 'smoke'
    | 'orange'
    | 'yellow'
    | 'indigo';
type ButtonVariants = 'flat' | 'outline' | 'ghost';
type ButtonSizes = 'xxs' | 'xs' | 'small' | 'base' | 'large' | 'xl';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    color: ButtonColors;
    disabled?: boolean;
    rounded?: boolean;
    size?: ButtonSizes;
    variant?: ButtonVariants;
    type?: 'button' | 'submit' | 'reset';
    className?: string;
}

const button = cva('border flex items-center gap-3 justify-center', {
    variants: {
        color: {
            'light-blue': [
                'bg-blue-100 border-blue-100 text-blue-800',
                'hover:bg-blue-200 hover:border-blue-200',
                'focus:bg-blue-200 focus:border-blue-200',
            ],
            blue: [
                'bg-blue-500 border-blue-500 text-blue-500',
                'hover:bg-blue-600 hover:border-blue-600',
                'focus:bg-blue-600 focus:outline focus:outline-blue-200 focus:border-blue-600',
            ],
            white: [
                'bg-white border-gray-500 text-black',
                'hover:bg-gray-300 hover:text-gray-700 hover:border hover:border-gray-600',
                'focus:!bg-gray-300 focus:text-gray-700 focus:border-gray-700',
            ],
            red: [
                'bg-red-500 border-red-500 text-red-500',
                'hover:bg-red-600 hover:border-red-600',
                'focus:bg-red-600 focus:outline focus:outline-red-200 focus:border-red-600',
            ],
            orange: [
                'bg-orange-500 border-orange-500 text-orange-500',
                'hover:bg-orange-600 hover:border-orange-600',
                'focus:bg-orange-600 focus:outline focus:outline-orange-200 focus:border-orange-600',
            ],
            green: [
                'bg-green-500 border-green-500 text-green-500',
                'hover:bg-green-700 hover:border-green-700',
                'focus:bg-green-700 focus:outline focus:outline-green-200 focus:border-green-700',
            ],
            gray: [
                'bg-gray-800 border-gray-800 text-white',
                'hover:bg-gray-700 hover:border-gray-700',
                'focus:bg-gray-700 focus:border-gray-200',
            ],
            'light-gray': [
                'bg-gray-200 border-gray-200 text-gray-800',
                'hover:bg-gray-300 hover:border-gray-300',
                'focus:bg-gray-300 focus:border-gray-300',
            ],
            purple: [
                'bg-purple-800 border-purple-800 text-purple-500',
                'hover:bg-purple-700 hover:border-purple-700',
                'focus:bg-purple-600 focus:outline focus:outline-purple-200 focus:border-purple-500',
            ],
            smoke: [
                'bg-smoke-700 border-smoke-700 text-white',
                'hover:bg-smoke-600 hover:border-smoke-600',
                'focus:bg-smoke-600 focus:border-smoke-600',
            ],
            yellow: ['bg-yellow-100 text-yellow-800'],
            indigo: 'bg-indigo-100 text-indigo-800',
        },
        variant: {
            flat: [],
            outline: ['bg-white', 'hover:bg-gray-200', 'focus:bg-gray-300 focus:!outline-none'],
            ghost: [
                'bg-transparent border-none',
                'hover:!bg-gray-300 hover:border-gray-100',
                'focus:!bg-gray-300 focus:outline focus:!outline-gray-100',
            ],
        },
        size: {
            xxs: 'p-1 text-sm',
            xs: 'px-3 py-2 text-xs',
            small: 'px-3 py-2 text-sm',
            base: 'px-5 py-2.5 text-sm',
            large: 'px-5 py-3',
            xl: 'px-6 py-3.5',
        },
        disabled: {
            true: ['disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500 cursor-not-allowed'],
        },
        rounded: {
            true: ['rounded-full'],
            false: ['rounded-lg'],
        },
    },
    compoundVariants: [
        { color: 'gray', variant: 'outline', className: '!text-gray-800 focus:!border-gray-800' },
        { color: 'gray', variant: 'outline', rounded: false, disabled: false, className: '!border-gray-800' },
        {
            color: ['blue', 'green', 'red', 'gray', 'purple', 'smoke', 'orange'],
            variant: 'flat',
            className: 'text-white',
        },
        {
            color: ['blue', 'green', 'red', 'gray', 'purple', 'smoke', 'orange'],
            variant: 'outline',
            rounded: false,
            className: ['hover:!bg-gray-200', 'focus:!bg-gray-300'],
        },
        {
            rounded: true,
            variant: 'outline',
            className: [
                'border-transparent bg-white',
                'hover:bg-transparent hover:border-transparent',
                'focus:!bg-gray-50 focus:!outline-none',
            ],
        },
        {
            color: 'white',
            variant: 'outline',
            className: ['text-blue-500', 'hover:bg-gray-200', 'focus:bg-gray-300'],
        },
        { rounded: true, size: 'xs', className: '!p-1' },
        { rounded: true, size: 'small', className: '!p-2' },
        { rounded: true, size: 'base', className: '!p-2.5' },
        { rounded: true, size: 'large', className: '!p-3' },
        { rounded: true, size: 'xl', className: '!p-3.5' },
        {
            variant: 'outline',
            disabled: true,
            className: 'disabled:!bg-white disabled:!text-gray-200 !border-gray-200',
        },
        {
            variant: 'ghost',
            disabled: true,
            className: 'disabled:!bg-transparent disabled:!text-gray-200 disabled:!border-gray-200',
        },
        {
            variant: 'ghost',
            color: 'gray',
            className: '!text-gray-800',
        },
        {
            variant: 'ghost',
            color: 'white',
            className: ['!bg-transparent !border-none', 'hover:bg-gray-300'],
        },
        {
            variant: 'ghost',
            disabled: true,
            className: 'disabled:!bg-transparent disabled:!text-gray-400 disabled:!border-gray-200',
        },
    ],
    defaultVariants: {
        color: 'blue',
        variant: 'flat',
        size: 'base',
        rounded: false,
    },
});

export function Button(props: ButtonProps) {
    const { color, size, disabled, variant, rounded, className } = props;
    return (
        <button
            {...props}
            className={button({
                color,
                size,
                disabled,
                variant,
                rounded,
                className,
            })}
        >
            {props.children}
        </button>
    );
}
