import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export enum Roles {
    ADMIN = 'ADMIN',
    FINANCIAL_ANALYST = 'FINANCIAL_ANALYST',
    FINANCE_USER = 'FINANCE_USER',
    SALES_AGENT = 'SALES_AGENT',
    CERTIFIER = 'CERTIFIER',
    APPRAISER = 'APPRAISER',
    COLLECTION_AGENT = 'COLLECTION_AGENT',
    DELIVERIES = 'DELIVERIES',
    PROCESSOR = 'PROCESSOR',
    C2C_SALES_AGENT = 'C2C_SALES_AGENT',
    MARKETING_AGENT = 'MARKETING_AGENT',
}

export const roles = {
    [Roles.ADMIN]: 'Administrador',
    [Roles.FINANCIAL_ANALYST]: 'Analista Financiero',
    [Roles.FINANCE_USER]: 'Agente de Finanzas',
    [Roles.SALES_AGENT]: 'Agente de Ventas',
    [Roles.CERTIFIER]: 'Certificador',
    [Roles.APPRAISER]: 'Tasador',
    [Roles.COLLECTION_AGENT]: 'Agente de Cobros',
    [Roles.PROCESSOR]: 'Tramitador',
    [Roles.DELIVERIES]: 'Entregas C2C',
    [Roles.C2C_SALES_AGENT]: 'Agente de Ventas C2C',
    [Roles.MARKETING_AGENT]: 'Agente de Marketing',
};

type ProfileStore = {
    roles: Roles[];
    testRole?: Roles;
    getProfileRoles: () => Roles[];
    setProfileRole: (roles: Roles[]) => void;
    setTestRole: (testRole: Roles | undefined) => void;
    isAdmin: (bypassTesting?: boolean) => boolean;
    hasRequiredRoles: (checkRoles: Roles[], bypass?: boolean) => boolean;
};

export const useProfileStore = create(
    persist(
        (set, get: () => ProfileStore): ProfileStore => ({
            roles: [],
            testRole: undefined,
            getProfileRoles: () => get().roles,
            setProfileRole: (roles) => set(() => ({ roles })),
            setTestRole: (testRole) => set(() => ({ testRole })),
            isAdmin: (bypassTesting = false) => {
                if (!bypassTesting && get().testRole) {
                    return false;
                }
                return get().roles?.includes(Roles.ADMIN);
            },
            hasRequiredRoles: (checkRoles, bypass = true) => {
                if (get().testRole) {
                    return checkRoles.includes(get().testRole as Roles);
                }
                if (bypass && get().roles?.includes(Roles.ADMIN)) {
                    return true;
                }
                return get().roles?.some((role: Roles) => checkRoles.includes(role));
            },
        }),
        {
            name: 'roles',
            storage: createJSONStorage(() => localStorage),
            partialize: (state) =>
                Object.fromEntries(Object.entries(state).filter(([key]) => ['testRole'].includes(key))),
        },
    ),
);
