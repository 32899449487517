import { RefObject, useEffect } from 'react';

export function useClickOutside<E extends HTMLElement>(ref: RefObject<E>, onClickOutisde: () => void) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent): any {
            if (ref.current && !ref.current.contains(event.target as unknown as Node)) {
                onClickOutisde();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, onClickOutisde]);
}
